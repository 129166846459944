import { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import type { NavigateOptions } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import type { SupportedLanguage } from 'i18n';
import { DEFAULT_LANGUAGE, languages } from 'i18n';

import { Analytics } from 'services/analytics';

export const useChangeLanguage = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  return (lng: SupportedLanguage) => {
    const prevLanguage = i18n?.language;
    i18n?.changeLanguage(lng);

    if (prevLanguage === lng) return;

    Analytics.sendEvent({ event: 'change_language_tap', data: { type: lng } });

    const url = new URL(window.location.href);
    const pathSegments = url.pathname.split('/');

    const lang = pathSegments.find((segment) => languages.includes(segment as SupportedLanguage));

    if (lang) {
      url.pathname = pathSegments.join('/').replace(`${lang}`, `${lng}`);
    } else {
      if (url.pathname === '/app') {
        url.pathname = `/app/${lng}`;
      } else {
        url.pathname = `/app/${lng}/${pathSegments[pathSegments.length - 1]}`;
      }
    }

    if (lng === DEFAULT_LANGUAGE) {
      navigate(url.pathname?.replace('/en', ''));
    } else {
      navigate(url.pathname);
    }
  };
};

export const useCurrentLanguage = () => {
  const { i18n } = useTranslation();
  return i18n?.language;
};

export const useBasePath = () => {
  const { i18n } = useTranslation();
  return useMemo(() => {
    return i18n?.language === DEFAULT_LANGUAGE ? '' : `/${i18n?.language}`;
  }, [i18n?.language]);
};

export const useLocaleNavigate = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  return useCallback(
    (path: string, options?: NavigateOptions) => {
      if (path?.includes('https')) {
        window.location.href = path;
        return;
      }

      const getLocalizedHref = (url: string) => {
        return `/app/${i18n.language}${url?.replace('/app', '')}`;
      };

      const preparedHref = path.startsWith('/') ? path : `/${path}`;

      const pathWithLocale =
        i18n.language === 'en' ? `/app${preparedHref.replace('/app', '')}` : getLocalizedHref(preparedHref);

      window.scrollTo(0, 0); // Scrolls to the top of the page
      if (options) {
        navigate(pathWithLocale, options);
        return;
      }
      navigate(pathWithLocale);
    },
    [navigate, i18n.language]
  );
};

export const useLocalizedHref = (path: string) => {
  const { i18n } = useTranslation();
  return useMemo(
    () =>
      i18n.language === 'en' ? `/app${path?.replace('/app', '')}` : `/app/${i18n.language}${path?.replace('/app', '')}`,
    [i18n.language, path]
  );
};
