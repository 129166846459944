import type { ChangeEventHandler, DragEventHandler } from 'react';
import React, { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import upload from 'assets/img/icons/ab-import_export_editor_2_4_4/upload.svg';
import classNames from 'classnames';
import { config } from 'configuration';

import { AVAILABLE_FORMATS_AB_IMPORT_EXPORT_EDITOR_2_4_4 } from 'components/uploadButtons/uploadButtonABImportEditExport244';

import { Analytics } from 'services/analytics';

import { selectEditMultipleDocuments } from 'data/selectors/documents';

import { getFileFormatFromFileList } from 'utils/getFileFormatFromFileList';
import { Logger } from 'utils/logger';
import showToast from 'utils/toast';
import { validateSelectedFiles } from 'utils/validation-abImportExporteditor244';

import type { MainColors } from 'ts/constants/general';
import type { IService } from 'ts/interfaces/services/service';
import type { InternalFileType } from 'ts/types/file';

import s from '../drag-n-drop-new-design.module.scss';

interface IProps {
  format: InternalFileType | InternalFileType[];
  onSelectFile: (files: File[]) => void;
  setError: (value: string | null) => void;
  onClick?: (method: string) => void;
  color?: MainColors;
  service?: IService;
}

export const DragNDropABImportExportEditor244: React.FC<IProps> = ({ format, onSelectFile, setError, onClick }) => {
  const { t } = useTranslation();
  const inputFileRef = React.useRef<HTMLInputElement>(null);
  const logger = useMemo(() => new Logger('DragNDrop'), []);
  const [isDragging, setIsDragging] = React.useState(false);
  const uploadedFiles = useSelector(selectEditMultipleDocuments);

  const processFiles = useCallback(
    (files: FileList | null) => {
      if (!files || files.length === 0) return;
      if (!inputFileRef.current) return;

      const validation = validateSelectedFiles({
        files,
        type: format,
        translator: t,
        isPDFConverter: true
      });

      if (!validation.valid && validation.error) {
        setError(validation.error);
        logger.error('validation_error', { errors: validation.error });
        void Analytics.sendEvent({
          event: 'file_upload_status',
          data: {
            status: 'fail',
            place: 'main',
            errors: validation.error,
            file_format: getFileFormatFromFileList(files)
          }
        });
      } else {
        setError(null);
        onSelectFile(validation.files);
      }

      inputFileRef.current.value = '';
    },
    [format, t, setError, logger, onSelectFile]
  );

  const MAX_FILE_COUNT = 10;

  const onFileChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();

    if (!e.target.files) return;

    const newFiles = Array.from(e.target.files);
    const totalFiles = [...uploadedFiles, ...newFiles];

    if (totalFiles.length > MAX_FILE_COUNT) {
      showToast('error', t('global.upload_up_to', { count: MAX_FILE_COUNT }), 5000, 'complete_merge_error_message');
      e.target.value = '';
      return;
    }

    processFiles(e.target.files);
  };

  const onDragOver: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const onDragLeave: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const onDrop: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    setIsDragging(false);
    processFiles(e.dataTransfer.files);
  };

  const classesDragNDrop = {
    [s.dragNDrop]: true
  };

  const onBtnClick = React.useCallback(() => {
    if (!inputFileRef.current) return;

    Analytics.sendEvent({
      event: 'features_tap',
      data: {
        method: 'click'
      }
    });

    if (onClick) {
      onClick('button_click');
    }

    inputFileRef.current.click();
  }, [onClick]);

  const supportedFormatsTitles = ['PDF', 'DOCX', 'PPTX', 'XLSX', 'JPEG'];
  const isUploadedFilesEmpty = uploadedFiles.length === 0;

  return (
    <div
      className={classNames(
        classesDragNDrop,
        'tablet:!w-[556px] flex flex-col items-center justify-center rounded-lg border border-dashed border-[#D2294B] transition-all duration-300 !p-3 tablet:!p-5 tablet:!rounded-[48px] cursor-pointer',
        isDragging && '!bg-white shadow-drop-zone',
        !isUploadedFilesEmpty && 'tablet:!h-[178px]'
      )}
      onDrop={onDrop}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      data-testid='drag-n-drop-container'
    >
      <div
        className={classNames(
          'bg-white flex flex-col items-center justify-center w-full h-full !p-4 tablet:!p-6 rounded-lg'
        )}
        onClick={onBtnClick}
        role='button'
      >
        {isUploadedFilesEmpty && (
          <img
            src={upload}
            alt='upload'
            className='tablet:w-[63px] tablet:h-[68px] w-[12px] h-[12px]'
          />
        )}
        <button className='text-black px-6 py-2 flex justify-center items-center gap-3 mt-2 mb-4 rounded-2xl'>
          <span className='tablet:block hidden text-[#D2294B] text-[16px] leading-normal tablet:text-[18px] tablet:leading-[22px] font-[500]'>
            {t('popups.upload_modal_ab_import_export_editor_2_4_4.click_and_drag')}
          </span>
          <span className='tablet:hidden text-black text-[16px] leading-normal tablet:text-[18px] tablet:leading-[22px] font-[700]'>
            {t('landing.converter_pdf.CTA.buttonLabel')}
          </span>
        </button>

        <div className='text-[14px] leading-[16px] font-[500] text-center tablet:mb-2 mb-2 mx-6 mobile:mx-0 mobile:block hidden text-[#575757]'>
          {t('popups.upload_modal_ab_import_export_editor_2_4_4.size_limit')}
        </div>

        <div className='flex flex-row items-center justify-center gap-1'>
          <div className='text-[14px] leading-[16px] font-[500] text-center tablet:mb-6 mb-6 mx-6 mobile:mx-0 mobile:block hidden'>
            {t('popups.upload_modal_ab_import_export_editor_2_4_4.supported_formats')}
          </div>
          {supportedFormatsTitles.map((title) => (
            <div
              key={title}
              className='text-[12px] leading-[16px] font-[500] text-center tablet:mb-6 mb-6 mx-6 mobile:mx-0 mobile:block hidden bg-[#F6F6F6] rounded-sm px-2 py-1'
            >
              {title}
            </div>
          ))}
        </div>
      </div>

      <input
        type='file'
        ref={inputFileRef}
        accept={AVAILABLE_FORMATS_AB_IMPORT_EXPORT_EDITOR_2_4_4}
        onChange={onFileChange}
        size={config.api.maxFileSizeMb * 1024 * 1024}
        multiple
        data-testid='choose-file-button'
      />
    </div>
  );
};
