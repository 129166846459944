import { useDispatch, useSelector } from 'react-redux';

import { countPdfPages } from 'helpers/countPdfPages';
import { useLocaleNavigate } from 'hooks/useLocaleNavigate';
import cloneDeep from 'lodash/cloneDeep';

import { Analytics } from 'services/analytics';

import { setConvertDocumentData, setPDFfileContent, updateDownloadProgress } from 'data/actions/documents';
import { toggleModal } from 'data/actions/modals';
import { userEmailSelector } from 'data/selectors/user';

import { blobToBase64 } from 'utils/blobToBase64';
import { formatFileSize } from 'utils/formatFileSize';

import { PAGE_LINKS } from 'ts/constants/page-links';
import { EModalTypes } from 'ts/enums/modal.types';
import type { IConvertDocumentDto, IEditDocumentDto } from 'ts/interfaces/documents/document';
import type { IService } from 'ts/interfaces/services/service';

export const SPLIT_FILE_KEY = 'SPLIT';

const useFileUploadAndSplit = ({ service }: { service?: IService; modalType?: string }) => {
  const navigate = useLocaleNavigate();
  const dispatch = useDispatch();
  const email = useSelector(userEmailSelector);

  // after upload a file run the progress animation
  const handleUpdateDownloadProgress = () => {
    let progressValue = 90;
    const intervalId = setInterval(() => {
      progressValue += 1;
      dispatch(updateDownloadProgress(progressValue));
      if (progressValue >= 100) clearInterval(intervalId);
    }, 50);
  };

  const analyticsEventUpload = ({
    success,
    size,
    errorCode,
    fileCounter
  }: {
    success: boolean;
    size: number;
    errorCode?: number;
    fileCounter?: number;
  }) => {
    void Analytics.sendEvent({
      event: 'file_upload_status',
      data: {
        status: success ? 'success' : 'fail',
        place: 'additional',
        errorCode,
        size: formatFileSize(size),
        accurate_size: size / 1000000,
        fileCounter: fileCounter || 1,
        file_format: '.pdf',
        is_validation_error: success ? 'false' : 'true'
      }
    });
  };

  const handleEditDocument = (dataToEdit: IEditDocumentDto, signUp?: boolean) => {
    handleUpdateDownloadProgress();
    return setTimeout(
      async () => {
        const dataToEditFileCopy = cloneDeep(dataToEdit);

        delete dataToEditFileCopy?.fileBase64;

        try {
          // set converted data to localStorage and use this data for google auth
          localStorage.setItem(
            'dataToEdit',
            JSON.stringify({
              file: dataToEditFileCopy,
              service: service?.path,
              email: email,
              documentId: dataToEdit.documentId,
              fileBase64: dataToEdit.fileBase64
            })
          );
          // set converted data to localStorage and use this data for google auth
          localStorage.setItem(
            'dataToConvert',
            JSON.stringify({
              file: dataToEditFileCopy,
              service: service?.path,
              email: email,
              serviceType: service?.serviceType,
              fileBase64: dataToEdit.fileBase64
            })
          );
        } catch (e) {
          console.log('Error', e);
          // set converted data to localStorage and use this data for google auth
          localStorage.setItem(
            'dataToEdit',
            JSON.stringify({
              file: dataToEditFileCopy,
              service: service?.path,
              email: email,
              documentId: dataToEdit.documentId,
              fileBase64: undefined
            })
          );
          // set converted data to localStorage and use this data for google auth
          localStorage.setItem(
            'dataToConvert',
            JSON.stringify({
              file: dataToEditFileCopy,
              service: service?.path,
              email: email,
              serviceType: service?.serviceType,
              fileBase64: undefined
            })
          );
        }

        dispatch(updateDownloadProgress(100));

        setTimeout(() => {
          if (!dataToEdit.fileBase64) {
            return;
          }

          dispatch(setPDFfileContent({ document: dataToEdit.fileBase64 }));
          dispatch(toggleModal({ visible: false }));
          navigate(`${PAGE_LINKS.EDITOR}?to=${SPLIT_FILE_KEY}`);
          window.scrollTo(0, 0); // Scrolls to the top of the page
        }, 1000);
      },
      signUp ? 0 : 1000
    );
  };

  const handleEditFile = (dataToEdit: IEditDocumentDto) => {
    handleEditDocument(dataToEdit);
  };

  const handleSaveFileToLocalStorage = async (file: File) => {
    try {
      dispatch(updateDownloadProgress(0));
      dispatch(
        toggleModal({
          type: EModalTypes.PROGRESS_EDIT_FILE,
          visible: true
        })
      );

      const from = file?.name?.split('.')?.pop()?.toUpperCase() || service?.from || 'PDF';
      const pagesCount = from === 'PDF' ? await countPdfPages(file) : 1;

      const fileBase64 = await blobToBase64(file);

      const dataToEdit: IEditDocumentDto = {
        filename: file.name,
        size: file.size,
        key: null,
        pagesCount,
        serviceType: service?.serviceType,
        fileBase64
      };

      const dataToConvert: IConvertDocumentDto = {
        filename: file.name,
        size: file.size,
        key: null,
        pagesCount,
        from,
        to: 'PDF',
        serviceType: service?.serviceType,
        fileBase64
      };

      // awaiting the downloading animation
      setTimeout(() => {
        dispatch(setConvertDocumentData(dataToConvert));

        handleEditFile(dataToEdit);
        analyticsEventUpload({ size: file?.size, success: true });
      }, 3000);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  // upload to s3 bucket and convert file
  const handleUploadFile = (file: File) => {
    if (!file) return;

    void Analytics.sendEvent({
      event: 'features_tap',
      data: {
        features_name: service?.path?.replace('/', '') || '',
        method: 'click'
      }
    });

    handleSaveFileToLocalStorage(file);
  };

  return {
    handleUploadFile,
    handleCompressDocument: handleEditDocument
  };
};

export default useFileUploadAndSplit;
