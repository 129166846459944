import { useDispatch, useSelector } from 'react-redux';

import axios, { HttpStatusCode } from 'axios';
// Helpers
import { countPdfPages } from 'helpers/countPdfPages';
import { getFileKeyFromAWSLink } from 'helpers/getFileKeyFromAWSLink';
// Hooks
import { useLocaleNavigate } from 'hooks/useLocaleNavigate';

// Services
import { Analytics } from 'services/analytics';

// Actions
import {
  convertDocument,
  downloadDocument,
  getConvertedFile,
  getUploadLink,
  setConvertDocumentData,
  updateDownloadProgress
} from 'data/actions/documents';
import { toggleModal } from 'data/actions/modals';
// Selectors
import {
  isUserAuthenticated,
  userDataSelector,
  userEmailSelector,
  userSubscriptionSelector
} from 'data/selectors/user';

import { formatFileSize } from 'utils/formatFileSize';
import { openPrintModalFromURL } from 'utils/openPrintModalFromUrl';

// Constants
import { PAGE_LINKS } from 'ts/constants/page-links';
import { EModalTypes } from 'ts/enums/modal.types';
import { UserStatus } from 'ts/enums/user.status';
import type { IConvertDocumentDto } from 'ts/interfaces/documents/document';
import type { IService } from 'ts/interfaces/services/service';

const useFileUploadConvertAndPrint = ({ service }: { service?: IService; modalType?: EModalTypes }) => {
  const navigate = useLocaleNavigate();
  const dispatch = useDispatch();
  const isAuth = useSelector(isUserAuthenticated);
  const userSubscription = useSelector(userSubscriptionSelector);
  const email = useSelector(userEmailSelector);
  const user = useSelector(userDataSelector);

  const analyticsEventUpload = ({
    success,
    size,
    errorCode,
    fileCounter,
    from
  }: {
    success: boolean;
    size: number;
    from: string;
    errorCode?: number;
    fileCounter?: number;
  }) => {
    void Analytics.sendEvent({
      event: 'file_upload_status',
      data: {
        status: success ? 'success' : 'fail',
        place: 'additional',
        errorCode,
        size: formatFileSize(size),
        accurate_size: size / 1000000,
        fileCounter: fileCounter || 1,
        file_format: `.${from?.toLowerCase() || '.pdf'}`,
        is_validation_error: success ? 'false' : 'true'
      }
    });
  };

  const handleConvertDocument = (dataToConvert: IConvertDocumentDto, signUp?: boolean, subscription?: any) => {
    const onSuccess = (res: any) => {
      let countRetries = 0;

      dispatch(updateDownloadProgress(100));
      if (dataToConvert.from === 'PDF' && dataToConvert.to === 'PDF' && res.id) {
        setTimeout(() => {
          dispatch(toggleModal({ visible: false }));
          openPrintModalFromURL(res.url);
        }, 500);
        return;
      }
      const interval = setInterval(() => {
        const onSuccessCallback = (res: any) => {
          console.log('onSuccessCallback', res);
          clearInterval(interval);
          setTimeout(() => {
            dispatch(updateDownloadProgress(100));
          }, 500);
          // awaiting the downloading animation
          setTimeout(() => {
            if (res?.processing_status === 'FAILED')
              return dispatch(
                toggleModal({
                  type: EModalTypes.FILE_UPLOAD_ERROR,
                  visible: true
                })
              );

            const handlePrint = (result: any) => {
              console.log('result', result);
              openPrintModalFromURL(result);
            };

            dispatch(downloadDocument(res?.id, handlePrint, handlePrint));
          }, 1000);
        };
        countRetries += 1;
        if (countRetries < 45)
          dispatch(
            getConvertedFile({
              fileId: res?.fileId,
              onSuccess: onSuccessCallback
            })
          );
        else clearInterval(interval);
      }, 1500);
    };

    const onFailed = (error: any) => {
      if (error?.response?.data?.message === 'error.convert.unsupported-formats') {
        dispatch(toggleModal({ visible: false }));
        dispatch(updateDownloadProgress(0));
        dispatch(
          toggleModal({
            visible: true,
            type: EModalTypes.FILE_UPLOAD_ERROR
          })
        );

        return;
      }

      if (error?.response?.status === HttpStatusCode.PaymentRequired) {
        dispatch(updateDownloadProgress(100));
        setTimeout(() => {
          dispatch(toggleModal({ visible: false }));
          dispatch(updateDownloadProgress(0));
          navigate(PAGE_LINKS.CHOOSING_PLAN);
          window.scrollTo(0, 0); // Scrolls to the top of the page
        }, 1000);
        return;
      }

      dispatch(toggleModal({ visible: true, type: EModalTypes.FILE_UPLOAD_ERROR }));
      dispatch(updateDownloadProgress(0));
    };
    if (!userSubscription && !subscription?.id && user?.status !== UserStatus.TEMPORARY) {
      dispatch(updateDownloadProgress(100));
      return setTimeout(
        () => {
          dispatch(convertDocument(dataToConvert, onSuccess, onFailed));
        },
        signUp ? 0 : 2000
      );
    }
    dispatch(convertDocument(dataToConvert, onSuccess, onFailed));
  };

  const handleConvertFile = (dataToConvert: IConvertDocumentDto) => {
    Analytics.sendEvent({
      event: 'converting_with_progress_view'
    });
    if (!isAuth) {
      setTimeout(() => {
        dispatch(updateDownloadProgress(100));
      }, 500);
      return setTimeout(() => {
        dispatch(
          toggleModal({
            type: EModalTypes.ENTER_EMAIL_ADDRESS,
            visible: true,
            options: {
              handleConvertDocument: (subscription: any) => handleConvertDocument(dataToConvert, true, subscription),
              signUp: true,
              servicePath: service?.path
            }
          })
        );
      }, 1000);
    }
    handleConvertDocument(dataToConvert);
  };

  const handleUploadFileByLinkToS3 = async (file: File, uploadLink: string, _to?: string) => {
    Analytics.sendEvent({
      event: 'upload_link_received'
    });
    try {
      const from = file?.name?.split('.')?.pop()?.toUpperCase() || service?.from || 'PDF';

      dispatch(updateDownloadProgress(0));

      const res = await axios.put(uploadLink, file);

      const pagesCount = from === 'PDF' ? await countPdfPages(file) : 1;

      const dataToConvert: IConvertDocumentDto = {
        filename: file.name,
        size: file.size,
        key: getFileKeyFromAWSLink(res?.request?.responseURL),
        url: res?.request?.responseURL,
        pagesCount,
        from,
        to: 'PDF'
      };
      Analytics.sendEvent({
        event: 'upload_file_to_bucket',
        data: {
          status: 'success',
          filename: file?.name
        }
      });
      // awaiting the downloading animation
      setTimeout(() => {
        dispatch(setConvertDocumentData(dataToConvert));

        // set converted data to localStorage and use this data for google auth
        localStorage.setItem(
          'dataToConvert',
          JSON.stringify({
            file: dataToConvert,
            service: service?.path,
            email: email,
            serviceType: service?.serviceType
          })
        );

        handleConvertFile(dataToConvert);
        analyticsEventUpload({ size: file?.size, success: true, from });
      }, 3000);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  // upload to s3 bucket and convert file
  const handleUploadFile = (file: File, to?: string) => {
    if (!file) return;

    void Analytics.sendEvent({
      event: 'file_from_provider_chosen',
      data: {
        features_name: service?.path?.replace('/', '') || '',
        method: 'click'
      }
    });

    dispatch(
      getUploadLink({
        filename: file?.name,
        onSuccess: (res: any) => {
          handleUploadFileByLinkToS3(file, res[0]?.url, to);
        },
        service
      })
    );
  };

  return {
    handleUploadFile,
    handleConvertDocument
  };
};

export default useFileUploadConvertAndPrint;
