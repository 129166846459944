import { useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import * as pdfjsLib from 'pdfjs-dist';
import axios, { HttpStatusCode } from 'axios';
import { countPdfPages } from 'helpers/countPdfPages';
import { getFileKeyFromAWSLink } from 'helpers/getFileKeyFromAWSLink';
import { useLocaleNavigate } from 'hooks/useLocaleNavigate';

import { Analytics } from 'services/analytics';

import {
  convertExistingDocument,
  downloadDocument,
  getTranslatedFile,
  getUploadLink,
  setTranslateDocumentData,
  translateDocument,
  translateFirstPage,
  updateDownloadProgress
} from 'data/actions/documents';
import { toggleModal } from 'data/actions/modals';
import { editDocumentDataSelector } from 'data/selectors/documents';
import {
  isUserAuthenticated,
  userDataSelector,
  userEmailSelector,
  userSubscriptionSelector
} from 'data/selectors/user';

import { formatFileSize } from 'utils/formatFileSize';

import { PAGE_LINKS } from 'ts/constants/page-links';
import { EModalTypes } from 'ts/enums/modal.types';
import { UserStatus } from 'ts/enums/user.status';
import type { ITranslateDocumentDto } from 'ts/interfaces/documents/document';
import type { IService } from 'ts/interfaces/services/service';
import { EServiceType } from 'ts/interfaces/services/service';

const useTranslateFile = ({ service, modalType }: { service?: IService; modalType?: EModalTypes }) => {
  const navigate = useLocaleNavigate();
  const dispatch = useDispatch();
  const isAuth = useSelector(isUserAuthenticated);
  const userSubscription = useSelector(userSubscriptionSelector);
  const email = useSelector(userEmailSelector);
  const editDocumentData = useSelector(editDocumentDataSelector());
  const documentForTranslate = useSelector((state: any) => state?.documents?.documentForTranslate?.document);
  const user = useSelector(userDataSelector);

  const isFlowStoppedRef = useRef(false);

  const analyticsEventUpload = ({
    success,
    size,
    errorCode,
    fileCounter,
    from
  }: {
    success: boolean;
    size: number;
    from: string;
    errorCode?: number;
    fileCounter?: number;
  }) => {
    void Analytics.sendEvent({
      event: 'file_upload_status',
      data: {
        status: success ? 'success' : 'fail',
        place: 'additional',
        errorCode,
        size: formatFileSize(size),
        accurate_size: size / 1000000,
        fileCounter: fileCounter || 1,
        file_format: `.${from?.toLowerCase() || '.pdf'}`,
        is_validation_error: success ? 'false' : 'true'
      }
    });
  };

  const handleTranslateDocument = (dataToTranslate: ITranslateDocumentDto, signUp?: boolean, subscription?: any) => {
    const onSuccess = (res: any) => {
      let countRetries = 0;

      if (dataToTranslate.fromLang === 'PDF' && dataToTranslate.toLang === 'PDF' && res.id) {
        dispatch(updateDownloadProgress(100));
        setTimeout(() => {
          dispatch(downloadDocument(res.id));
          dispatch(toggleModal({ visible: false }));
        }, 500);
        return;
      }
      const interval = setInterval(() => {
        const onSuccessCallback = (res: any) => {
          clearInterval(interval);

          // awaiting the downloading animation
          setTimeout(() => {
            if (res?.processing_status === 'FAILED')
              return dispatch(
                toggleModal({
                  type: EModalTypes.FILE_UPLOAD_ERROR,
                  visible: true
                })
              );

            dispatch(toggleModal({ visible: false }));

            dispatch(downloadDocument(res?.id));
          }, 1000);
        };
        countRetries += 1;
        if (countRetries < 45) dispatch(getTranslatedFile(res?.fileId, onSuccessCallback));
        else clearInterval(interval);
      }, 1500);
    };

    const onFailed = (error: any) => {
      dispatch(updateDownloadProgress(100));

      setTimeout(() => {
        if (error?.response?.data?.message === 'error.convert.unsupported-formats') {
          dispatch(toggleModal({ visible: true, type: EModalTypes.FILE_UPLOAD_ERROR }));
          dispatch(updateDownloadProgress(0));
          return;
        }

        if (error?.response?.status === HttpStatusCode.PaymentRequired) {
          dispatch(toggleModal({ visible: false }));
          dispatch(updateDownloadProgress(0));
          navigate(PAGE_LINKS.CHOOSING_PLAN);
          window.scrollTo(0, 0); // Scrolls to the top of the page
          return;
        }

        dispatch(toggleModal({ visible: true, type: EModalTypes.FILE_UPLOAD_ERROR }));
        dispatch(updateDownloadProgress(0));
      }, 1000);
    };

    if (!userSubscription && !subscription?.id && user?.status !== UserStatus.TEMPORARY) {
      dispatch(updateDownloadProgress(100));
      return setTimeout(
        () => {
          if (service?.serviceType !== EServiceType.TRANSLATE && editDocumentData?.id) {
            dispatch(
              convertExistingDocument({
                from: dataToTranslate.fromLang,
                to: dataToTranslate.toLang,
                fileId: editDocumentData.id,
                onSuccess,
                onFailed
              })
            );
          } else {
            dispatch(translateDocument(dataToTranslate, onSuccess, onFailed));
          }
        },
        signUp ? 0 : 2000
      );
    }

    dispatch(translateDocument(dataToTranslate, onSuccess, onFailed));
  };

  const handleTranslateFile = (dataToTranslate: ITranslateDocumentDto) => {
    Analytics.sendEvent({
      event: 'converting_with_progress_view'
    });
    if (!isAuth) {
      setTimeout(() => {
        dispatch(updateDownloadProgress(100));
      }, 500);
      return setTimeout(() => {
        dispatch(
          toggleModal({
            type: EModalTypes.ENTER_EMAIL_TRANSLATE,
            visible: true,
            options: {
              handleTranslateDocument: (subscription: any) =>
                handleTranslateDocument(dataToTranslate, true, subscription),
              signUp: true,
              servicePath: service?.path,
              serviceType: service?.serviceType
            }
          })
        );
      }, 1000);
    }
    handleTranslateDocument(dataToTranslate);
  };

  const handleUploadFileByLinkToS3 = async (file: File, uploadLink: string, to?: string) => {
    Analytics.sendEvent({
      event: 'upload_link_received'
    });
    try {
      const getModalType = () => {
        if (modalType) return modalType;
        return EModalTypes.PROGRESS_TRANSLATE_FILE;
      };

      dispatch(updateDownloadProgress(0));
      dispatch(
        toggleModal({
          type: getModalType(),
          visible: true,
          options: { file, onUserClose: () => (isFlowStoppedRef.current = true) }
        })
      );

      const res = await axios.put(uploadLink, file);

      const from = file?.name?.split('.')?.pop()?.toUpperCase() || service?.from || 'PDF';
      const pagesCount = from === 'PDF' ? await countPdfPages(file) : 1;

      const dataToTranslate: ITranslateDocumentDto = {
        serviceType: service?.serviceType,
        filename: file.name,
        size: file.size,
        key: getFileKeyFromAWSLink(res?.request?.responseURL),
        url: res?.request?.responseURL,
        pagesCount,
        fromLang: from,
        toLang: to || 'en'
      };
      Analytics.sendEvent({
        event: 'upload_file_to_bucket',
        data: {
          status: 'success',
          filename: file?.name
        }
      });
      // awaiting the downloading animation
      setTimeout(() => {
        if (isFlowStoppedRef.current) return;

        dispatch(setTranslateDocumentData(dataToTranslate));

        // set converted data to localStorage and use this data for google auth
        localStorage.setItem(
          'dataToConvert',
          JSON.stringify({
            file: dataToTranslate,
            service: service?.path,
            email: email,
            serviceType: EServiceType.TRANSLATE
          })
        );

        handleTranslateFile(dataToTranslate);
        analyticsEventUpload({ size: file?.size, success: true, from });
      }, 3000);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  // upload to s3 bucket and convert file
  const handleUploadFile = (file: File, to?: string) => {
    if (!file) return;

    void Analytics.sendEvent({
      event: 'features_tap',
      data: {
        features_name: service?.path?.replace('/', '') || '',
        method: 'click'
      }
    });

    dispatch(
      getUploadLink({
        filename: file?.name,
        onSuccess: (res: any) => {
          handleUploadFileByLinkToS3(file, res[0]?.url, to);
        },
        service
      })
    );

    if (!isAuth) {
      const fileUrl = URL.createObjectURL(documentForTranslate);
      pdfjsLib
        .getDocument(fileUrl)
        .promise.then(async (_pdf) => {
          try {
            const { PDFDocument } = await import('pdf-lib');
            const newPdf = await PDFDocument.create();

            const pdfData = await documentForTranslate.arrayBuffer();
            const pdfDoc = await PDFDocument.load(pdfData, { ignoreEncryption: true });

            const [copiedPage] = await newPdf.copyPages(pdfDoc, [0]);
            newPdf.addPage(copiedPage);

            const pdfBytes = await newPdf.save();

            const blob = new Blob([pdfBytes], { type: 'application/pdf' });
            const firstPageFile = new File([blob], 'first_page.pdf', { type: 'application/pdf' });

            const buffer = await firstPageFile.arrayBuffer();
            dispatch(translateFirstPage(buffer));

            URL.revokeObjectURL(fileUrl);
          } catch (error) {
            console.error('Error creating PDF:', error);
          }
        })
        .catch((error) => {
          console.error('Error loading PDF:', error);
          URL.revokeObjectURL(fileUrl);
        });
    }
  };

  return { handleTranslateDocument, handleUploadFile };
};

export default useTranslateFile;
